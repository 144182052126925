/* eslint-disable no-restricted-syntax */
import { useMemo } from 'react';
import { addDays, fromUnixTime, getUnixTime } from 'date-fns';

import { MappedRow, UseColumnsArgs, UseColumnsResult } from 'pages/CostManagementV2/types';
import {
  EmptyCell,
  FactoryCellDataLink,
  FactoryCellDatePicker,
  FactoryCellEmptyOn,
  FactoryCellInput,
  FactoryCellMultiDateConverter,
  FactoryCellMutedHeader,
  FactoryCellSelect,
  FactoryCellSelectBinary,
  FactoryCellText,
} from 'shared/components/DataGrid';
import { PackageTypeName } from 'shared/constants/package';
import { inputTypes } from 'shared/components/Input/constants';
import {
  FilterSearchAutocomplete,
  FilterSearchAutocompleteMultiple,
  FilterSearchAutocompleteQuery,
  FilterSearchDatePicker,
  FilterSearchInput,
  FilterSearchSelectBinary,
} from 'shared/components/Search';
import {
  CostDateType,
  CostManagementsV2QueryVariables,
  ItemsOptionsQuery,
  ItemsOptionsQueryVariables,
  LocationAutocompleteOptionsQuery,
  LocationAutocompleteOptionsQueryVariables,
} from 'shared/types';
import { mapToSelectOptions, mapValuesSeparatedWithComma } from 'shared/utils';
import { itemsOptionsQuery, locationAutocompleteOptionsQuery } from 'shared/operations/query';
import { FactoryCellActions } from 'pages/CostManagementV2/components/FactoryCellActions';
import { FactoryCellAddLocationCost } from 'pages/CostManagementV2/components/FactoryCellAddLocationCost';
import { FilterStatus } from 'shared/components/Search/Filter/FilterSearchStatus/FilterSearchStatus';

import styles from '../CostManagementView.module.css';
import { FactoryCellStatus } from '../components/FactoryCellStatus';
import { filterPermissions } from '../constants';
import { FactoryCellType } from '../components/FactoryCellType';

const CellStatus = FactoryCellStatus();
const CellType = FactoryCellType();

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const CellMultiDateConverter = FactoryCellMultiDateConverter(
  'MMM/dd/yyyy',
  ['costManagementUpdatedAt'],
  ['Cost Management'],
  'Cost Date',
);

const useColumns = ({
  canApproveAndReject,
  canEditIsDisabled,
  canSubmit,
  canUpload,
  currenciesOptions,
  deletableCostDateStatuses,
  disablePastForDatePicker,
  enableEditRowWithStatusesWhitelist,
  hideCanAutoApproveColumn,
  hideIsDisabledColumn,
  languageCode,
  onOpenCommentsModalClick,
  onOpenDeleteModalClick,
  role,
  setUnsubmittedRows,
  shouldShowParentCompanyColumn,
  shouldUseStickyColumns,
  updateCostDatesApprovalStatusCallback,
  updateFilter,
  updateIsDisabled,
  userOptions,
}: UseColumnsArgs): UseColumnsResult =>
  useMemo(() => {
    const CellActions = FactoryCellActions({
      canApproveAndReject,
      deletableCostDateStatuses,
      canSubmit,
      updateCostDatesApprovalStatusCallback,
      onOpenCommentsModalClick,
      onOpenDeleteModalClick,
    });

    const itemDepartmentFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="itemDepartment"
        label="GP Item Department"
        onSubmitFilter={updateFilter}
      />
    );

    const itemClassFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="itemClass"
        label="GP Item Class"
        onSubmitFilter={updateFilter}
      />
    );

    const itemNameFilter = (
      <FilterSearchAutocompleteQuery<
        ItemsOptionsQuery,
        ItemsOptionsQueryVariables,
        CostManagementsV2QueryVariables
      >
        filterFieldName="itemName"
        label="GP Item Name"
        mapDataToOptions={a =>
          mapToSelectOptions(
            a.items.items.map(item => ({
              ...item,
              languageCode: languageCode.split('-').join(''),
            })),
          )
        }
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          name: value,
          languageCode: languageCode || 'en-US',
        })}
        onSubmitFilter={updateFilter}
        query={itemsOptionsQuery}
      />
    );

    const eachUPCsFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="eachUPCs"
        label="Each UPC"
        mapUserInputToFilerParams={value => ({
          eachUPCs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const caseUPCsFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="caseUPCs"
        label="Case UPC"
        mapUserInputToFilerParams={value => ({
          caseUPCs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const eachVINsFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="eachVINs"
        label="Each VIN"
        mapUserInputToFilerParams={value => ({
          eachVINs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const caseVINsFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="caseVINs"
        label="Case VIN"
        mapUserInputToFilerParams={value => ({
          caseVINs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const itemIdFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="itemExtIDs"
        label="GP Item ID"
        mapUserInputToFilerParams={value => ({
          itemExtIDs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const statusFilter = (
      <FilterStatus<CostManagementsV2QueryVariables>
        filterFieldName="costDateStatuses"
        filterOptions={new Set<string>(filterPermissions[role]?.vendorCostManagement.options)}
        label="Status"
        onSubmitFilter={updateFilter}
        useUrlQueryParameter
      />
    );

    const currencyIdsFilter = (
      <FilterSearchAutocompleteMultiple<CostManagementsV2QueryVariables>
        filterFieldName="currencyIDs"
        label="Currency"
        onSubmitFilter={updateFilter}
        options={currenciesOptions}
      />
    );

    const typeFilter = (
      <FilterStatus<CostManagementsV2QueryVariables>
        filterFieldName="costDateTypes"
        filterOptions={new Set<string>([CostDateType.Regular, CostDateType.Promo])}
        label="Type"
        onSubmitFilter={updateFilter}
      />
    );

    const supplierNameFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="supplierName"
        label="GP Vendor Name"
        onSubmitFilter={updateFilter}
      />
    );

    const supplierIdsFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="supplierExtIDs"
        label="GP Vendor Number"
        mapUserInputToFilerParams={value => ({
          supplierExtIDs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
        title="DC sources will use the SCDC Supplier Id"
      />
    );

    const parentCompanyFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="parentCompany"
        label="Parent Company"
        onSubmitFilter={updateFilter}
      />
    );

    const locationNameFilter = (
      <FilterSearchAutocompleteQuery<
        LocationAutocompleteOptionsQuery,
        LocationAutocompleteOptionsQueryVariables,
        CostManagementsV2QueryVariables
      >
        filterFieldName="locationName"
        label="GP Location Name"
        mapDataToOptions={data => mapToSelectOptions(data.locations.locations)}
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          name: value,
        })}
        onSubmitFilter={updateFilter}
        query={locationAutocompleteOptionsQuery}
      />
    );
    const locationIdFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="locationExtIDs"
        label="GP Location ID"
        mapUserInputToFilerParams={value => ({
          locationExtIDs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const opcoNameFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="opcoName"
        label="GP OpCo Name"
        onSubmitFilter={updateFilter}
      />
    );

    const opcoIdsFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="opcoIDs"
        label="GP OpCo ID"
        mapUserInputToFilerParams={value => ({
          opcoIDs: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const opcoStateProvinceRegionFilter = (
      <FilterSearchInput<CostManagementsV2QueryVariables>
        filterFieldName="opcoStateProvinceRegion"
        label="OpCo State/Province/Region"
        mapUserInputToFilerParams={value => ({
          opcoStateProvinceRegion: value,
        })}
        onSubmitFilter={updateFilter}
      />
    );

    const isDisabledFilter = (
      <FilterSearchSelectBinary<CostManagementsV2QueryVariables>
        filterFieldName="isDisabled"
        label="Is Disabled"
        onSubmitFilter={updateFilter}
      />
    );

    const canAutoApproveFilter = (
      <FilterSearchSelectBinary<CostManagementsV2QueryVariables>
        filterFieldName="canAutoApprove"
        label="Can Auto Approve"
        onSubmitFilter={updateFilter}
        useUrlQueryParameter
      />
    );

    const updatedAtFilter = (
      <FilterSearchDatePicker<CostManagementsV2QueryVariables>
        filterFieldName="updatedAt"
        label="Last Updated"
        onSubmitFilter={updateFilter}
      />
    );

    const updatedByFilter = (
      <FilterSearchAutocomplete<CostManagementsV2QueryVariables>
        filterFieldName="updatedBy"
        hideInputWhenNoOptions
        label="Changed By"
        mapUserAutocompleteValueSelectToFilterParams={option => ({
          updatedBy: option!.value,
        })}
        onSubmitFilter={updateFilter}
        options={userOptions}
      />
    );

    const CellInputPackage = FactoryCellEmptyOn<MappedRow>(
      FactoryCellInput({
        type: inputTypes.Money,
        min: 0.0,
        max: 100000,
        dependencies: ['effectiveDate', 'currency', 'isDisabled', 'type', 'endDate'],
        isDisabled: ({ currency, effectiveDate, isDisabled, status }: MappedRow) =>
          !!(
            !effectiveDate ||
            effectiveDate < Date.now() / 1e3 ||
            !canUpload ||
            !currency ||
            (!canEditIsDisabled && isDisabled) ||
            (enableEditRowWithStatusesWhitelist &&
              !enableEditRowWithStatusesWhitelist.includes(status))
          ),
        resetStateOnNewValue: true,
      }),
      (_, { isAddLocationCostRow }) => isAddLocationCostRow,
      null,
      false,
      ['effectiveDate', 'currency', 'isDisabled', 'type', 'endDate'],
    );

    const deriveHasDateDependenciesChanged = (previous: MappedRow, next: MappedRow): boolean => {
      const previousRows = (previous.parent ? previous.parent.rows : previous.rows) ?? [];
      const nextRows = (next.parent ? next.parent.rows : next.rows) ?? [];

      return (
        nextRows.length !== previousRows.length ||
        !!previousRows.some(
          (row, index) => !!nextRows[index] && nextRows[index].effectiveDate !== row.effectiveDate,
        ) ||
        previous.parent?.effectiveDate !== next.parent?.effectiveDate ||
        previous.effectiveDate !== next.effectiveDate
      );
    };

    const today = new Date();
    const tomorrow = addDays(today, 1);
    let startDate: Date;
    if (disablePastForDatePicker) {
      startDate = tomorrow;
    }
    const CellEffectiveDate = FactoryCellDatePicker<MappedRow>({
      disableKeyboardInput: true,
      dependencies: ['isDisabled', 'type'],
      isDisabled: ({ isDisabled, status }) =>
        !!(
          (!canEditIsDisabled && isDisabled) ||
          !canUpload ||
          (enableEditRowWithStatusesWhitelist &&
            !enableEditRowWithStatusesWhitelist.includes(status))
        ),
      deriveDisabledDates: ({ costId, parent, rows, type }) => {
        const allRows = parent ? parent.rows?.concat(parent) : rows;
        const disabledDates: number[] = [];

        if (!allRows || allRows.length === 0) {
          return disabledDates;
        }

        for (const row of allRows) {
          if (row.type !== type || !row.effectiveDate || row.costId === costId) {
            continue;
          }
          if (!row.endDate) {
            disabledDates.push(row.effectiveDate);
          } else {
            for (
              let currentDate = fromUnixTime(row.effectiveDate);
              currentDate <= fromUnixTime(row.endDate);
              currentDate = addDays(currentDate, 1)
            ) {
              disabledDates.push(getUnixTime(currentDate));
            }
          }
        }

        return disabledDates;
      },
      deriveHasDependenciesChanged: deriveHasDateDependenciesChanged,
      deriveDateRange: () => ({ minDate: startDate, maxDate: undefined }),
    });

    const CellEndDate = FactoryCellDatePicker<MappedRow>({
      disableKeyboardInput: true,
      dependencies: ['isDisabled', 'effectiveDate', 'type'],
      isDisabled: ({ effectiveDate, isDisabled, status, type }: MappedRow) =>
        !!(
          !effectiveDate ||
          type !== CostDateType.Promo ||
          !canUpload ||
          (!canEditIsDisabled && isDisabled) ||
          (enableEditRowWithStatusesWhitelist &&
            !enableEditRowWithStatusesWhitelist.includes(status))
        ),
      deriveDateRange: ({ effectiveDate, parent, rows }) => {
        const allRows = parent ? parent.rows?.concat(parent) : rows;

        if (!allRows || allRows.length === 0) {
          return { minDate: undefined, maxDate: undefined };
        }

        let minDate;
        let maxDate;

        if (effectiveDate) {
          minDate = fromUnixTime(effectiveDate);
        }

        for (const row of allRows) {
          if (row.type === CostDateType.Promo && row.effectiveDate) {
            const currentEffectiveDate = fromUnixTime(row.effectiveDate);

            if (
              minDate &&
              currentEffectiveDate > minDate &&
              (!maxDate || currentEffectiveDate <= maxDate)
            ) {
              maxDate = addDays(currentEffectiveDate, -1);
            }
          }
        }

        return { minDate, maxDate };
      },
      isBlankCell: ({ type }) => type !== CostDateType.Promo,
    });

    const CellTypeOrAddLocationCost = FactoryCellEmptyOn<MappedRow>(
      CellType,
      (_, { isAddLocationCostRow }) => isAddLocationCostRow,
      null,
      false,
      ['isDisabled'],
    );

    const CellAddLocationCost = FactoryCellAddLocationCost(
      setUnsubmittedRows,
      [],
      deriveHasDateDependenciesChanged,
    );

    const CellEffectiveDateOrAddLocationCost = FactoryCellEmptyOn<MappedRow>(
      CellAddLocationCost,
      (_, { isAddLocationCostRow }) => isAddLocationCostRow,
      CellEffectiveDate,
      true,
      ['isDisabled', 'type'],
      deriveHasDateDependenciesChanged,
    );

    return [
      {
        Header: () => itemDepartmentFilter,
        accessor: 'itemDepartment',
        Cell: FactoryCellDataLink<MappedRow>({
          maxWidth: '104px',
          useI18n: false,
        }),
        sticky: shouldUseStickyColumns ? 'left' : undefined,
        width: 140,
      },
      {
        Header: () => itemClassFilter,
        accessor: 'itemClass',
        Cell: FactoryCellDataLink<MappedRow>({
          maxWidth: '104px',
          useI18n: false,
        }),
        width: 150,
        sticky: shouldUseStickyColumns ? 'left' : undefined,
      },
      {
        Header: () => itemIdFilter,
        accessor: 'itemId',
        Cell: FactoryCellDataLink<MappedRow>({
          maxWidth: '104px',
          useI18n: false,
        }),
        sticky: shouldUseStickyColumns ? 'left' : undefined,
        width: 120,
      },
      {
        Header: () => itemNameFilter,
        accessor: 'itemName',
        Cell: FactoryCellDataLink<MappedRow>({
          maxWidth: '254px',
        }),
        width: 270,
        sticky: shouldUseStickyColumns ? 'left' : undefined,
      },
      shouldShowParentCompanyColumn
        ? { Header: () => parentCompanyFilter, accessor: 'parentCompany' }
        : null,
      {
        Header: 'Case Quantity',
        accessor: 'caseCount',
        Cell: ({ value }: { value: number }) => (
          <span className={styles.caseCountCell}>{value}</span>
        ),
      },
      { Header: () => eachUPCsFilter, accessor: 'eachUPC' },
      { Header: () => caseUPCsFilter, accessor: 'caseUPC' },
      { Header: () => eachVINsFilter, accessor: 'eachVIN' },
      { Header: () => caseVINsFilter, accessor: 'caseVIN' },
      { Header: () => statusFilter, accessor: 'status', Cell: CellStatus },
      {
        Header: () => typeFilter,
        accessor: 'type',
        Cell: CellType,
        SubCell: CellTypeOrAddLocationCost,
      },
      {
        Header: <div style={{ whiteSpace: 'normal', width: 70 }}>Each Cost % Change</div>,
        accessor: 'autoValidationEachCost',
      },
      {
        Header: <div style={{ whiteSpace: 'normal', width: 70 }}>Case Cost % Change</div>,
        accessor: 'autoValidationCaseCost',
      },
      hideCanAutoApproveColumn
        ? null
        : {
            Header: () => canAutoApproveFilter,
            accessor: 'canAutoApprove',
          },
      { id: 'actions', Header: 'Actions', accessor: 'status', Cell: CellActions },
      {
        Header: 'Note',
        accessor: 'comments',
        Cell: FactoryCellDataLink<MappedRow>({
          maxWidth: '300px',
          useI18n: false,
        }),
      },
      hideIsDisabledColumn
        ? null
        : {
            Header: () => isDisabledFilter,
            accessor: 'isDisabled',
            Cell: FactoryCellSelectBinary({
              isDisabled: () => !canUpload,
              update: updateIsDisabled,
            }),
            SubCell: EmptyCell,
          },
      {
        Header: 'Effective Date',
        accessor: 'effectiveDate',
        Cell: CellEffectiveDate,
        SubCell: CellEffectiveDateOrAddLocationCost,
        width: 200,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        Cell: CellEndDate,
        width: 200,
      },
      {
        id: 'cost',
        Header: FactoryCellMutedHeader({
          mainText: 'Cost',
          mutedText: '(*Packaging Type)',
        }),
        columns: [
          {
            Header: 'Each Cost',
            accessor: PackageTypeName.Each,
            Cell: CellInputPackage,
            width: 96,
          },
          {
            Header: 'Case Cost',
            accessor: PackageTypeName.Case,
            Cell: CellInputPackage,
            width: 96,
          },
        ],
      },
      {
        Header: currencyIdsFilter,
        accessor: 'currency',
        Cell: FactoryCellSelect<MappedRow, string>({
          options: currenciesOptions,
          isDisabled: ({ effectiveDate, isDisabled, status }) =>
            !!(
              !effectiveDate ||
              !canUpload ||
              (!canEditIsDisabled && isDisabled) ||
              (enableEditRowWithStatusesWhitelist &&
                !enableEditRowWithStatusesWhitelist.includes(status))
            ),
          showError: ({ currency, rows }) =>
            rows!.some(
              ({ currency: subRowCurrency, isAddLocationCostRow, isUnsubmitted }) =>
                !isAddLocationCostRow && !isUnsubmitted && currency !== subRowCurrency,
            ),
          dependencies: ['isDisabled'],
        }),
        SubCell: FactoryCellText<MappedRow>({
          isRed: ({ currency, parent }) => parent!.currency !== currency,
          pickValue: ({ currency }) =>
            currency ? currenciesOptions.find(({ value }) => value === currency)!.label : '',
          dependencies: ['currency'],
        }),
      },
      { Header: () => supplierNameFilter, accessor: 'supplierName' },
      { Header: () => supplierIdsFilter, accessor: 'supplierId', width: 140 },
      { Header: () => opcoNameFilter, accessor: 'opcoName' },
      { Header: () => opcoIdsFilter, accessor: 'opcoId' },
      {
        Header: () => opcoStateProvinceRegionFilter,
        accessor: 'opcoStateProvinceRegion',
        width: 200,
      },
      { Header: () => locationNameFilter, accessor: 'locationName' },
      { Header: () => locationIdFilter, accessor: 'locationId' },
      { Header: 'GP Location Address', accessor: 'locationAddress' },
      { Header: 'GP Location City', accessor: 'locationCity' },
      { Header: 'GP Location State/Province/Region', accessor: 'locationStateProvinceRegion' },
      { Header: 'GP Location Zip', accessor: 'locationPostalCode' },
      {
        Header: () => updatedByFilter,
        accessor: 'updatedBy',
      },
      {
        Header: () => updatedAtFilter,
        accessor: 'updatedAt',
        Cell: CellMultiDateConverter,
        width: 170,
      },
    ].filter(Boolean) as UseColumnsResult;
  }, [currenciesOptions, canUpload, languageCode, userOptions]);

export { useColumns };
